import i18n from "@/i18n";
import { Commit } from "vuex";
import moment from "moment";
import { RootState } from "@/store";
import { request, url } from "@/api";

export interface Oauth2 {
  channel: "google" | "discord" | "qq" | "wechat" | "wechat_oa";
  action: "callback" | "get_info";
  scope?: "base" | "userinfo";
}

export interface AppState {
  name: string;
  loading: boolean;
  language: string;
  countryCode: string;
  isTest: boolean;
  isCN: boolean;
  isMobile: boolean;
  isInWeChat: boolean;
  isInQQ: boolean;
  isInAndroid: boolean;
  isInIOS: boolean;
  oss: string;
}

const USERAGENT = navigator.userAgent.toUpperCase();

const state: AppState = {
  name: "Angry Miao",
  loading: true,
  language: "cn",
  countryCode: "",
  isTest: url.isTest,
  isCN: true,
  isMobile: false,
  isInWeChat: USERAGENT.includes("MICROMESSENGER"),
  isInQQ: USERAGENT.includes(" QQ") && !USERAGENT.includes("MQQBROWSER"),
  isInAndroid: USERAGENT.includes("ANDROID"),
  isInIOS:
    USERAGENT.includes("IPHONE") ||
    USERAGENT.includes("IPAD") ||
    USERAGENT.includes("IPOD"),
  oss: "https://cdn-www.dry---studio.com/"
};

interface Context {
  commit: Commit;
  state: AppState;
  rootState: RootState;
}

const actions = {
  setLoading({ commit }: Context, loading: boolean) {
    commit("SET_LOADING", loading);
  },
  setLanguage({ commit }: Context, language: string) {
    let lang = language.toLowerCase();
    switch (lang) {
      case "cn":
      case "zh-cn":
      case "zh-tw":
        lang = "cn";
        moment.locale("zh-CN");
        document.documentElement.setAttribute("lang", "zh-CN");
        break;
      default:
        lang = "en";
        moment.locale(lang);
        document.documentElement.setAttribute("lang", lang);
        break;
    }
    i18n.locale = lang;
    commit("SET_LANGUAGE", lang);
  },
  checkIP({ commit }: Context) {
    return request({
      url: url.checkIP,
      headers: { "No-Authorization": true }
    }).then((res: any) => {
      if (res && res.iso_code) {
        commit("SET_COUNTRY_CODE", res.iso_code);
      }
      return res;
    });
  },
  checkMobile({ commit }: Context) {
    const width = document.body.offsetWidth;
    if (width < 1000) {
      commit("SET_IS_MOBILE", true);
      return;
    } else {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
      ];
      const isMobile = toMatch.some(toMatchItem => {
        return navigator.userAgent.match(toMatchItem);
      });
      commit("SET_IS_MOBILE", isMobile);
    }
  }
};

const mutations = {
  SET_LOADING: (state: AppState, loading: boolean) => {
    state.loading = loading;
  },
  SET_LANGUAGE: (state: AppState, language: string) => {
    state.language = language;
    state.isCN = language === "cn";
  },
  SET_IS_MOBILE: (state: AppState, isMobile: boolean) => {
    state.isMobile = isMobile;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
